import React, { createContext, useState } from "react";

export const contextData = createContext();

export const ContextProvider = ({ children }) => {
    const [userdata, setUserdata] = useState(null);

    const login = (user) => {
        setUserdata(user);
    };

    const logout = () => {
        setUserdata(null);
    };

    return (
        <contextData.Provider value={{ userdata, login, logout }}>
            {children}
        </contextData.Provider>
    );
};