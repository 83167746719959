// src/components/CreateLunchOrderForm.js
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { useCreateLunchOrder, useFetchLunchOrders, useUpdateLunchOrder, useDeleteSingleLunchOrders } from '../../../hooks/foodOrders/lunchOrders'
import weekNumberData from '../../../Partials/week'
import Spinner from '../../../Partials/Spinner';
import { toast } from "react-toastify";

const CreateLunchOrderForm = () => {
    const createLunchOrder = useCreateLunchOrder();
    const updateLunchOrders = useUpdateLunchOrder();
    const deleteLunchOrders = useDeleteSingleLunchOrders();
    const [weekNumber, setWeekNumber] = useState(0);
    const [tillbehorValues, setTillbehorValues] = useState({});
    const generateDefaultValues = (weekNumber) => {
        const defaults = {};
        [1, 2, 3].forEach((alt) => {
            Array.from({ length: 5 }).forEach((_, dayIndex) => {
                const day = dayIndex + 1;
                defaults[`${weekNumber}_alt${alt}_day${day}`] = 0;
            });
        });
        return defaults;
    };
    
    const { control, handleSubmit, setValue, getValues, reset } = useForm({
        defaultValues: generateDefaultValues(weekNumber), // Define a function to generate initial default values
    });
    const { data: lunchOrders, isLoading } = useFetchLunchOrders({ weekNumber });
    const onSubmit = (data) => {
        if (weekNumber === undefined) {
            toast('Välj vecka först!');
            return;
        }
        const orders = [];
        // Prepare orders for each alternative and weekday
        for (let alt = 1; alt <= 3; alt++) {
            for (let day = 1; day <= 5; day++) {
                const key = `${weekNumber}_alt${alt}_day${day}`;
                if (data[key] > 0) { // Only add if the amount is greater than 0
                    orders.push({
                        weekOfYear: weekNumber,
                        amount: data[key],
                        weekDay: day,
                        alt: alt,
                    });
                }
            }
        }
        if (lunchOrders.length > 0) {
            toast('Användaren har redan beställt lunch!');
            return;
        } else {
            createLunchOrder.mutate({ orders });
        }
    };
    React.useEffect(() => {
        reset(); // Clear form values
        if (lunchOrders) {
           lunchOrders.forEach((lunchOrder) => {
                    const key = `${weekNumber}_alt${lunchOrder.alt}_day${lunchOrder.weekDay}`;
                    setValue(key, lunchOrder.amount); // Set values for the form
                });
        }
    }, [lunchOrders, setValue, reset]);

    const handleUpdate = (weekNumber) => {
        if (!lunchOrders.length) {
            toast('Vänligen beställ först!');
            return;
        }
    
        const updatedOrders = [];
        const newOrders = [];
    
        // Iterate over alt (1 to 3) and week days (1 to 5)
        for (let alt = 1; alt <= 3; alt++) {
            for (let day = 1; day <= 5; day++) {
                const key = `${weekNumber}_alt${alt}_day${day}`;
                const amount = getValues(key);
    
                if (amount >= 0) {
                    // Find the specific lunchOrder with matching alt and weekDay
                    const existingLunchOrder = lunchOrders.find(
                        order => order.alt === alt && order.weekDay === day
                    );
    
                    if (existingLunchOrder) {
                        // Update the existing lunchOrder
                        updatedOrders.push({
                            _id: existingLunchOrder._id,
                            weekDay: parseInt(day),
                            alt: parseInt(alt),
                            amount: parseInt(amount),
                        });
                    } else {
                        // Create a new lunchOrder for this alt and day
                        newOrders.push({
                            weekDay: parseInt(day),
                            alt: parseInt(alt),
                            amount: parseInt(amount),
                        });
                    }
                }
            }
        }
    
        if (updatedOrders.length > 0) {
            console.log('Updating existing lunchOrders');
            updateLunchOrders.mutate({ weekNumber, lunchOrders: updatedOrders });
        }
    
        if (newOrders.length > 0) {
            console.log('Creating new lunchOrders');
            createLunchOrder.mutate({
                orders: newOrders.map(order => ({
                    weekOfYear: weekNumber,
                    ...order,
                })),
            });
        }
    
        if (updatedOrders.length === 0 && newOrders.length === 0) {
            toast('Ingen ändring hittades.');
        }
    };
    
    
    const handleDelete = () => {
        if (!weekNumber) {
            toast('Välj en användare och vecka först!');
            return;
        }
    
        if (window.confirm('Är du säker på att du vill ta bort lunchbeställningarna för den här användaren?')) {
            deleteLunchOrders.mutate({ weekNumber }, {
                onSuccess: () => {
                    // Reset form values
                    reset(); // Clears all react-hook-form values
                    setTillbehorValues({}); // Resets Tillbehör state
                    toast('Lunchbeställningarna har tagits bort.');
                },
                onError: (error) => {
                    toast('Kunde inte ta bort lunchbeställningarna. Försök igen.');
                    console.error('Delete error:', error);
                },
            });
        }
    };
    
    if (isLoading) {
        return <Spinner />;
    }
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="formFrame">
                <select
                    name="weekOfYear"
                    className="form-select"
                    value={weekNumber}
                    onChange={(e) => {setWeekNumber(e.target.value);setTillbehorValues(0); reset()}}
                >
                    <option value={0}>Välj vecka</option>
                    {weekNumberData.map((week, index) => {
                        return (<>
                            <option key={index} value={week}>
                                {week}
                            </option></>
                        );
                    })}
                </select>
            </div>
            <Row>
                <Col sm={10}>
                    <h5 className='font-size-xs text-light'></h5>
                </Col>
                <Col sm={2} className="m-auto">
                    <i
                        className="fa-solid fa-file-pen glow me-3 text-light"
                        onClick={() => handleUpdate(weekNumber)}
                    ></i>
                    <span onClick={() => handleDelete(weekNumber)}>
                        <i className="fa-solid fa-trash-can glow text-light"></i>
                    </span>
                </Col>
            </Row>
            <Table hover>
                <thead className='text-light'>
                    <tr>
                        <th></th>
                        {['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'].map((day, index) => (
                            <th key={index}>{day}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="text-light">
                    {[1, 2, 3].map((alt, index, arr) => (
                        <tr key={alt}>
                            <td>{index === arr.length - 1 ? 'Tillbehör' : `Alt ${alt}`}</td>
                            {Array.from({ length: 5 }).map((_, dayIndex) => {
                                const day = dayIndex + 1;
                                const alt1Key = `${weekNumber}_alt1_day${day}`;
                                const alt2Key = `${weekNumber}_alt2_day${day}`;
                                const tillbehorKey = `${weekNumber}_alt3_day${day}`;

                                // Handle the calculation of Tillbehör (Alt 1 + Alt 2)
                                const handleAltChange = () => {
                                    const alt1Value = Number(getValues(alt1Key) || 0);
                                    const alt2Value = Number(getValues(alt2Key) || 0);
                                    const sum = alt1Value + alt2Value;

                                    // Update the state for "Tillbehör"
                                    setTillbehorValues((prevState) => ({
                                        ...prevState,
                                        [`${weekNumber}_alt3_day${day}`]: sum,
                                    }));

                                    // Set the value for Tillbehör in react-hook-form
                                    setValue(tillbehorKey, sum);
                                };

                                return (
                                    <td key={dayIndex}>
                                        {index === arr.length - 1 ? (
                                            <Controller
                                                name={tillbehorKey}
                                                control={control}
                                                value={tillbehorValues[tillbehorKey] || 0}  // Use value instead of defaultValue
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="number"
                                                        min={0}
                                                        placeholder="Tillbehör"
                                                        onBlur={(e) => {
                                                            field.onChange(e); // Allow editing of Tillbehör
                                                            setTillbehorValues((prevState) => ({
                                                                ...prevState,
                                                                [`${weekNumber}_alt3_day${day}`]: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <Controller
                                                name={`${weekNumber}_alt${alt}_day${day}`}
                                                control={control}
                                                defaultValue={0}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="number"
                                                        min={0}
                                                        placeholder="Amount"
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleAltChange();
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button type="submit" variant="primary">Create Lunch Orders</Button>
        </Form>
    );
};

export default CreateLunchOrderForm;
